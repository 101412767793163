import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Box,
	ButtonBase, Dialog, DialogContent,
	Divider, Grid, IconButton, Stack, TextField, Typography,
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// project imports
import Transitions from 'components/extends/Transitions';
import { useFormik } from 'formik';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { Roles, User } from 'rebrain-irm-model';
import * as yup from 'yup';
import { ServerRequests, UserRequests } from '../../../apollo';


// ==============================|| LOCALIZATION ||============================== //

/**
 * 'Enter your subject'
 * yup.string Expected 0 arguments, but got 1 */
const validationSchema = yup.object({
	subject: yup.string().max(30, 'Subject should be of maximum 30 characters length').required('Subject is required'),
	content: yup.string().max(765, 'Subject should be of maximum 765 characters length').required('Message is required')
});

const HelpSection = () => {
	const theme = useTheme();
	const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();

	// State
	const [open, setOpen] = React.useState(false);
	const [user, setUser] = useState<User>()

	//Form
	const formik = useFormik({
		initialValues: {
			subject: '',
			content: ''
		},
		validationSchema,
		onSubmit: ({ subject, content }: { subject: string, content: string }) => {
			formik.setSubmitting(true)
			ServerRequests.contactUs(subject, content)
				.then(() => {
					formik.resetForm()
					openSuccessSnackbar('Your message is sent.')
				})
				.catch((err) => {
					openErrorSnackbar(err.message)
				})
				.finally(() => {
					formik.setSubmitting(false)
				})
		}
	});

	// Effects
	useEffect(() => {
		setTimeout(() => {
			__loadCurrentUser()
		}, 1000)
	}, [])

	// methods
	const __loadCurrentUser = () => {
		UserRequests.meBasic().then((user) => {
			setUser(user)
		}).catch(() => { })
	}

	return (
		<>
			<Box
				sx={{
					ml: 2,
					[theme.breakpoints.down('md')]: {
						ml: 1
					}
				}}
			>
				<ButtonBase sx={{ borderRadius: '12px' }}>
					<Avatar
						variant="rounded"
						sx={{
							width: "32px",
							height: "32px",
							border: '1px solid',
							borderColor: `primary.light`,
							backgroundColor: `primary.light`,
							color: `primary.dark`,
							transition: 'all .2s ease-in-out',
							'&[aria-controls="menu-list-grow"],&:hover': {
								borderColor: `primary.main`,
								background: `primary.main`,
								color: `primary.main`
							}
						}}

						aria-haspopup="true"
						onClick={e => setOpen(true)}
						color="inherit"
					>
						<QuestionMarkOutlinedIcon sx={{ fontSize: '1.3rem' }} />
					</Avatar>
				</ButtonBase>
			</Box>
			<Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
				<Transitions position="center" in={open}>
					<DialogContent>
						<Stack spacing={2}>
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="h4">User guide</Typography>
								<IconButton onClick={() => setOpen(false)}>
									<CloseRoundedIcon />
								</IconButton>
							</Stack>

							<Stack
								spacing={2}
								justifyContent="center" alignItems="center" direction="row">
								{
									user?.role === Roles.CLINICIAN &&
									<LoadingButton
										variant="contained"
										download
										target={"_blank"}
										href={`${process.env.REACT_APP_SERVER}/server/user-guide`}>
										English documentation
									</LoadingButton>
								}
								{
									user?.role !== Roles.CLINICIAN &&
									<LoadingButton
										variant="contained"
										download
										target={"_blank"}
										href={`${process.env.REACT_APP_SERVER}/server/user-guide?lng=FR`}>
										French documentation
									</LoadingButton>
								}
							</Stack>
							<Divider variant="fullWidth" />
							<Typography variant="h4">Contact us</Typography>
							<div>
								<form onSubmit={formik.handleSubmit}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												fullWidth
												id="subject"
												name="subject"
												label="Subject"
												defaultValue={formik.values.subject}
												onChange={formik.handleChange}
												error={formik.touched.subject && Boolean(formik.errors.subject)}
												helperText={formik.touched.subject && formik.errors.subject}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												multiline
												rows={5}
												id="content"
												name="content"
												label="Message"
												type="content"
												defaultValue={formik.values.content}
												onChange={formik.handleChange}
												error={formik.touched.content && Boolean(formik.errors.content)}
												helperText={formik.touched.content && formik.errors.content}
											/>
										</Grid>
										<Grid item xs={12}>
											<Stack direction="row" justifyContent="flex-end">
												<LoadingButton
													startIcon={<SendOutlinedIcon />}
													loading={formik.isSubmitting}
													variant="contained" color="primary" type="submit">
													Send
												</LoadingButton>
											</Stack>
										</Grid>
									</Grid>
								</form>
							</div>
						</Stack>
					</DialogContent>
				</Transitions>
			</Dialog>
		</>
	);
};

export default HelpSection;
